import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Link } from "gatsby"
import Arrow from "../images/arrow.svg"



const Article = styled.article`
  max-width:1400px;
  padding: 1em;
  margin: auto;
`

const ZakresUslug = () => (
  <Layout>
    <SEO title="Zakres usług" />
    <Article className="allservices">
    <dl>
      <dt>Obsługa księgowa</dt>
        <dd>
          <p>W ramach świadczenia usługi Zarządzania Nieruchomością oferujemy wykonywanie czynności związanych z gospodarką ekonomiczno - finansową nieruchomości.</p>
          <ol>
            <li>Prowadzenie pozaksięgowej ewidencji kosztów utrzymania nieruchomości wspólnej oraz ewidencji zaliczek uiszczanych na pokrycie tych kosztów, zgodnie z obowiązującymi przepisami;</li>
            <li>Przesyłanie na adres e-mailowy członków Zarządu Wspólnoty kwartalnych informacji finansowych dotyczących Wspólnoty Mieszkaniowej, w terminie do ostatniego dnia miesiąca za kwartał poprzedni. Pierwsza informacja finansowa zostanie przesłana w trzecim miesiącu od momentu rozpoczęcia współpracy;</li>
            <li>Przygotowywanie płatności do podpisu/autoryzacji lub w przypadku powierzenia zarządu dokonywanie płatności;</li>
            <li>Udzielanie informacji księgowych właścicielom oraz użytkownikom lokali w godzinach dyżurów telefonicznych przez 8h tygodniowo oraz poprzez e-mail;</li>
            <li>Przygotowywanie naliczeń na podstawie uchwał Wspólnoty Mieszkaniowej;</li>
            <li>Przygotowywanie rozliczeń planów gospodarczych;</li>
            <li>Sporządzanie rocznych sprawozdań finansowych;</li>
            <li>Przygotowywanie comiesięcznych wezwań do zapłaty;</li>
            <li>Rozliczanie raz w roku z właścicielami lokali opłat z tytułu kosztów utrzymania nieruchomości, kosztów zużytych mediów, funduszu na remonty i modernizacje i innych funduszy celowych (jeśli występują);</li>
            <li>Wydawanie poświadczeń bądź zaświadczeń właścicielom lokali;</li>
            <li>Dostęp przez WWW do strefy mieszkańców:<br/>
                <b>a)</b> Przydzielanie każdemu właścicielowi lokalu indywidualnego loginu i hasła;<br/>
                <b>b)</b> Udostępnianie właścicielom lokali informacji na temat obecnej wysokości opłat i bieżącego salda na indywidualnych kontach rozliczeniowych. Aktualizacja danych indywidualnych właścicieli lokali 2 razy w tygodniu w przypadku obsługi rachunku bankowego z elektronicznym dostępem dla Zleceniobiorcy lub aktualizacja każdorazowo po 3 dniach od dostarczenia Zleceniobiorcy papierowych wersji wyciągów bankowych w przypadku obsługi rachunku bankowego bez elektronicznego dostępu;
            </li>
          </ol>
        </dd>
      <dt>Obsługa administracyjna operacyjna</dt>
        <dd>
          <ol>
            <li>Przechowywanie i prowadzenie dokumentacji Wspólnoty Mieszkaniowej;</li>
            <li>Nadzór nad bieżącym funkcjonowaniem nieruchomości, w tym ciągłości dostaw usług od podmiotów zewnętrznych;</li>
            <li>Kontrola poprawności i jakości wykonywania usług przez firmy zewnętrzne (dostawy mediów, sprzątanie, konserwacja urządzeń, inne);</li>
            <li>Pełnienie funkcji informacyjnej dla właścicieli nieruchomości i jej użytkowników, w tym umożliwienie kontaktów telefonicznych, mailowych oraz podczas wizytacji nieruchomości;</li>
            <li>Przygotowywanie i obsługa zebrań Wspólnoty Mieszkaniowej;</li>
            <li>Monitorowanie podpisanych umów i ich terminów;</li>
            <li>Dokonywanie czynności związanych z doradztwem mającym na celu utrzymywanie nieruchomości w stanie niepogorszonym, a także w zakresie optymalizacji ponoszonych kosztów;</li>
            <li>Prowadzenie windykacji należności poprzez sporządzanie i przekazywanie wezwań do zapłaty;</li>
            <li>Nadzór nad wykonywaniem konserwacji oraz planowanych remontów;</li>
            <li>Dbanie o zadowolenie i komfort mieszkańców poprzez właściwe informowanie, bieżące rozwiązywanie zgłaszanych problemów, wychodzenie naprzeciw ich oczekiwaniom oraz zaspokajanie ich potrzeb.</li>
          </ol>
        </dd>
      <dt>Prowadzenie Książki Obiektu Budowlanego</dt>
        <dd>
          <ol>
            <li>Analiza przejętej dokumentacji nieruchomości, wskazanie jej braków oraz koniecznych działań uzupełniających;</li>
            <li>Nadzór i wykonywanie lub zlecanie wykonania w odpowiednich terminach obowiązkowych przeglądów,</li>
            <li>Nadzór i prowadzenie wpisów do Książki Obiektu Budowlanego;</li>
            <li>Nadzór nad terminowym wykonywaniem przeglądów wymaganych prawem.</li>
          </ol>
            <p> Finalną cenę świadczonych przez nas usług determinuje wiele czynników, dlatego we współpracy z nami nie znajdziesz sztywnych pakietów.</p>
        </dd>
    </dl>
    </Article>
    <Link style={{display:'block', margin:'auto', width:'fit-content'}} to="/"><button className="kmf-button">Powrót<img src={Arrow} alt="strzałka" /></button></Link>
  </Layout>
)

export default ZakresUslug
